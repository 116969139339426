export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyC17zuSaEvudCk2hXxpLQMbHSYzo9Zv4Us',
    authDomain: 'kxe-wedding-prod.firebaseapp.com',
    databaseURL: 'https://kxe-wedding-prod.firebaseio.com',
    projectId: 'kxe-wedding-prod',
    storageBucket: 'kxe-wedding-prod.appspot.com',
    messagingSenderId: '631914183874',
    appId: '1:631914183874:web:eeb9650060800e948b4f5b',
    measurementId: 'G-73LC4L4WET'
  }
};

