<div class="container">
  <div class="row pt-4">
    <div class="col-md-6 col-sm-12 text-center mx-auto">
      <h1>Wedding Admin Portal</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-sm-12 mx-auto">
      <!--Navbar-->
      <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light bg-light z-depth-0" [containerInside]="false">
          <!-- Navbar brand -->
          <mdb-navbar-brand><span style="padding: 0.1rem"></span></mdb-navbar-brand>
          <!-- Collapsible content -->
          <links class="my-n2">
              <!-- Links -->
              <ul class="navbar-nav mx-auto">
                <li class="nav-item" 
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  #rlaLogin="routerLinkActive"
                >
                  <a class="nav-link waves-dark" mdbWavesEffect routerLink="/">
                    Login
                    <span class="sr-only">
                    {{ rlaLogin.isActive ? '(current)' : ''}}
                    </span>
                  </a>
                </li>
                  <li class="nav-item" 
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    #rlaInvitation="routerLinkActive"
                    [hidden]="isNotAuthenticated"
                  >
                    <a class="nav-link waves-dark" mdbWavesEffect routerLink="/invitations">
                      Invitations
                      <span class="sr-only">
                      {{ rlaInvitation.isActive ? '(current)' : ''}}
                      </span>
                    </a>
                  </li>
              </ul>
          </links>
      </mdb-navbar>
      <!--/.Navbar-->
    </div>
  </div>
  <router-outlet></router-outlet>
</div>